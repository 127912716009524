<template>
  <div class="Reports__View">
    <EntityListLayout :tabs="tabs" ref="entity" @onNewEntitySave="onNewEntitySave">
      <template #title>משתמשים בקורס</template>
      <template #button>
        <a @click="addUser" class="link-button">
          משתמש חדש
        </a>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import CoursesService from "@/services/CoursesService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import UserEntity from "@/views/UserEntity.vue";
import DialogAddUser from '@/components/DialogAddUser.vue';
import {
  ModalProgrammatic as Modal,
} from 'buefy';
import FieldService from "@/services/FieldService";
import FilterService from "@/services/FilterService";

export default {
  name: "AdminSearchReports",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  created() {
    this.create();
  },
  methods: {
    create() {
      const reportsFields = FieldService.list("users");
      const reportsfilters = FilterService.list();

      const sideViewEntity = {
        component: UserEntity,
        preview: true,
        readOnly: false,
        title: "פרטי איש קשר",
        recordUrl: "user",
      };

      const excelExport = [
        {
          title: "יצוא טבלה",
          action: this.export,
        },
      ];
      const fields = [
        { ...reportsFields.Id, checked: true },
        { ...reportsFields.PrivateName, checked: true },
        { ...reportsFields.LastName, checked: true },
        { ...reportsFields.UserName, checked: true },
        { ...reportsFields.Phone, checked: true },
        { ...reportsFields.Address, checked: true },
        { ...reportsFields.Status, checked: true },
        { ...reportsFields.CreateDate, checked: true },
        { ...reportsFields.UserTypeId, checked: true },
        { ...reportsFields.Entity, checked: true },
      ];

      this.tabs = [
        {
          id: "AllCourseUsers",
          title: "כל המשתמשים",
          getDataAsync: this.getAllUsers,
          perPage: 100,
          fields,
          filters: [
            { ...reportsfilters.Groups, selected: this.$route.params.GroupIds },
          ],
          sortField: "LastPayDate",
          sortOrder: "desc",
          checkableRows: false,
          excelExport,
          fastSearch: true,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש משתמש",
          },
          sideViewEntity,
          tableActions: null,
          isAdminArea: true,
          defaultQuery: false
        },
      ];
    },
    onNewEntitySave() {
      this.$refs.entity.getData(this.tabs[0])
    },
    export({ loading, query }) {
      loading(true);
      CoursesService.exportUsers(this.$store.state.auth.course?.id, query)
        .then((r) => {
          this.saveFile(r.data, "יצוא משתמשים ממערכת SimAdmin");
        })
        .finally(() => {
          loading(false);
        });
    },
    getAllUsers({ cancelToken, ...rest }) {
      return CoursesService.getUsersByCourse(
        this.$store.state.auth.course?.id,
        {
          ...rest,
        },
        cancelToken.token
      ).then((r) => ({
        data: {
          TotalResults: r.data.totalResults,
          Items: r.data.items,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
    saveFile(data, filename) {
      const blob = new Blob([data], { type: this.excelMimeType });
      saveAs(blob, `${filename}.xlsx`);
    },
    loadUsers() {
      this.$refs.entity.getData(this.tabs[0]);
    },
    addUser() {
      Modal.open({
        component: DialogAddUser,
        props: {
          store: this.$store,
          onSuccess: this.onNewEntitySave
        },
        events: {
          customEvent: () => {
            this.loadUsers();
          }
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.Reports__View {
  height: 100%;

  .link-button {
    background: #ff7a59;
    border: none;
    border-radius: 3px;
    padding: 14px 25px;
    margin-left: 10px;
    color: white;
    cursor: pointer;
  }
}
</style>
